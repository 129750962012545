<template>
	<WalletMultiButton :data-key="$store.state.wallet.address" :openOnboardingUrls="false" :connecting="connecting" @copy="onCopy"
			@error="onWalletError" @connect="onConnected"
			@disconnect="onDisconnect"
			:wallets="[]"
			dark></WalletMultiButton>
</template>

<script>
import {WalletMultiButton} from "@alphabatem/vue2-wallet-adapter";

export default {
	name: "MultiWallet",
	components: {
		WalletMultiButton
	},
	props: {
		button_text: {
			type: String,
			default: function () {
				return "Connect Wallet"
			}
		}
	},
	data() {
		return {
			key: 0,
			attempts: 0,
			connectedNotif: false,
			connecting: false,
			wallet: null,
		}
	},
	methods: {
		onDisconnect() {
			console.log("MultiWallet::Disconnect")
			this.$toastr.e("Wallet disconnected")

			this.$store.commit("wallet/clear_wallet")
			this.$emit("disconnect")
		},

		onCopy(addr) {
			this.$toastr.s(addr, "Address copied to clipboard")
		},

		onWalletError(e) {
			this.$toastr.e(e, "Wallet Error")
		},

		onConnected(e) {
			console.log("Connected event", e)
			this.attemptConnect()
		},

		onWalletConnected() {
			console.debug("MultiWallet:onWalletConnected", this.wallet?.wallet, this.wallet?.wallet?.readyState, this.wallet?.publicKey?.toString(), this.wallet?.connected)

			this.key++
			this.connecting = false
			this.attempts = 0
			console.log("Connected", this.wallet?.publicKey?.toString())
			this.$emit("wallet-connected", this.wallet?.publicKey?.toString()); //Fire connected event
			this.$store.commit('wallet/set_wallet', this.wallet);
			this.$store.commit('wallet/set_wallet_address', this.wallet?.publicKey?.toString());
			this.$store.dispatch("wallet/walletConnected");
			this.$emit("connected", true)
			window.gtag("event", "wallet_connect")

			if (!this.connectedNotif) {
				this.$toastr.s(this.wallet?.publicKey?.toString(), "Wallet connected!")
				this.connectedNotif = true
			}
		},
		attemptConnect() {
			if (!this.wallet?.wallet || this.$store.state.wallet.wallet || this.attempts > 3)
				return

			console.log("Wallet not connected & not connecting", this.$store.state.wallet.wallet, this.wallet, this.wallet?.connected, this.wallet?.connecting, this.attempts)
			this.attempts++
			this.connecting = true
			this.wallet?.connect().catch(e => {
				console.error("Unable to connect to wallet", e)
				localStorage.removeItem("walletName")
			}).then(() => {
				if (this.wallet.connected)
					this.onWalletConnected()
			}).finally(() => {
				this.connecting = false
			})
		}
	},
	mounted() {
		// setTimeout(() => {
		// 	this.wallet = useWallet()
		// 	this.attemptConnect()
		// }, 600)

	}
}
</script>

<style scoped>
</style>
